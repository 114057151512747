<template>
  <div ref="loginComp" class="all-comp" @click="closeTicket">
    <!-- <div v-if="imgUrl" class="comp-left">
      <img :src="imgUrl" alt="">
    </div>
    <div class="comp sign-comp-wrap" :class="imgUrl ? 'borderRadius' : '' ">
      <SignIn
        v-if="show === 1"
        :is-login="isLogin"
        :quick-login-show="quickLoginShow"
        :config="config"
        @showWhat="showWhat"
        @setUserInfo="setUserInfo"
        @receiveVip="receiveVip"
        @set-quick-login-show="setQuickLoginShow"
      />
      <SignUp
        v-else-if="show === 2"
        :is-login="isLogin"
        :config="config"
        @showWhat="showWhat"
        @setUserInfo="setUserInfo"
        @getUserInfo="getUserInfo"
      />
      <Retrieve
        v-else-if="show === 3"
        :is-login="isLogin"
        :config="config"
        @showWhat="showWhat"
      />
      <SignUp
        v-else-if="show === 4"
        :is-login="isLogin"
        :config="config"
        :isPhoneLogin='true'
        @showWhat="showWhat"
        @setUserInfo="setUserInfo"
        @getUserInfo="getUserInfo"
      />
      <NewSignUp
        v-else-if="show === 5"
        :is-login="isLogin"
        :config="config"
        :isPhoneLogin='true'
        @showWhat="showWhat"
        @setUserInfo="setUserInfo"
        @getUserInfo="getUserInfo"
      />
    </div> -->
    <riskLoginPwd
      :showRiskLogin="showRiskLogin"
      :riskLoginLimit="riskLoginLimit"
      @closeRisk="closeRisk"
    />
  </div>
</template>

<script>
// import SignIn from './signIn.vue';
// import SignUp from './signUp.vue';
// import NewSignUp from './newSignUp';
// import Retrieve from './retrieve.vue';
import riskLoginPwd from "./riskLogin/index.vue";
export default {
  name: "SignComp",
  components: { riskLoginPwd },
  provide() {
    return {
      signComp: this,
    };
  },
  inject: ["app"],
  props: {
    userInfo: {
      type: Object,
    },
    isLogin: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      default: 1,
    },
    route: {
      type: Object,
    },
    config: {
      type: Object,
      default() {
        return {
          APIDOMAIN: "//api.chuangkit.com",
          APIDOMAINV2: "//api-v2.chuangkit.com",
        };
      },
    },
    // https://yapi.ckt.cn/project/58/interface/api/10180
    // 业务	APPID
    // 主站	10
    // 企业服务	11
    // 创意工具	12
    // 版权内容	13
    // 商业增长	14
    bannerAppId: {
      type: Number,
      default: 10,
    },
    redirect: {
      type: String,
      default: () => "",
    },
    state: {
      type: String,
      default: () => "",
    },
    trackerBaseParams: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      typeCache: 1, // HACK 解决关闭时子元素移除造成的父元素塌陷
      retryTimes: 0,
      quickLoginShow: true, // 是否显示快捷登录
      forward: {}, //弹框来源
      showRiskLogin: false, // 风控 登录限制
      // imgUrl: '', //运营位图片地址
    };
  },
  computed: {
    show() {
      return this.type > 0 ? this.type : this.typeCache;
    },
    nonNullBannerAppId() {
      return this.bannerAppId || 10;
    },
  },
  watch: {
    type(v, ov) {
      if (v === 0) {
        this.typeCache = ov;
      }
    },
    isLogin(val) {
      // 控制第三方登录跳转
      if (val) {
        this.showWhat(false);
      }
    },
    show(v) {
      if (v !== 1) {
        if (sessionStorage.getItem("wxAuth")) {
          sessionStorage.removeItem("wxAuth");
        }
      }
    },
  },
  created() {
    // this.getImgUrl();
    if (!window.$SDKVM) {
      const script = document.createElement("script");
      const host =
        location.host === "local.chuangkit.com"
          ? "https://local-www.chuangkit.com"
          : "";
      script.src = host + "/accountcenter/sdk/login.js";
      document.body.appendChild(script);
      script.addEventListener("load", () => {
        this.appendLoginSdk();
      });
    }
  },
  beforeMount() {
    if (this.isLogin) {
      this.showWhat(false);
    }
  },
  mounted() {
    try {
      if (!window.cktTracker) {
        new window.$Tracker({ baseParams: {}, alias: "cktTracker" });
      }
    } catch (error) {
      console.log(error);
    }
    // this.getCaptchaJsUrl();
    this.appendLoginSdk();
  },
  methods: {
    async appendLoginSdk() {
      if (!window.$SDKVM || !this.$refs.loginComp) return; //若登录SDK未初始化或组件未mounted，则取消渲染
      let forward =
        (sessionStorage.getItem("forward") &&
          JSON.parse(sessionStorage.getItem("forward"))) ||
        this.forward;
      this.forward = forward;
      let event_track_obj = {
        session_id: sessionStorage.getItem("uuid"),
        domain: window.location.protocol + "//" + window.location.host,
        platform: 1,
        ocpc_flag: "",
        ocpc_source: "",
        ocpc_time: "",
        utm_source: "",
        utm_medium: "",
        utm_campaign: "",
        utm_term: "",
        utm_content: "",
        forward_page_name: (forward && forward.forward_page_name) || "",
        forward_module_name: (forward && forward.forward_module_name) || "",
        ...(this.trackerBaseParams || {}),
      };
      let opcp_obj = {};
      try {
        const ckt_ocpc = localStorage.getItem("ckt_ocpc") || ""; //360 百度 ocpc
        const ckt_ocpc_url = localStorage.getItem("ckt_ocpc_url") || ""; //360 百度 ocpc
        const ckt_ocpc_time = localStorage.getItem("ckt_ocpc_time") || ""; // ckt_ocpc_time
        const ckt_ocpc_utmState = localStorage.getItem("ckt_utm_source") || ""; // ckt_ocpc_time
        const reg_utm_source = new RegExp(
          "(^|/?|&)utm_source=([^&]*)(&|$)",
          "i",
        );
        const reg_utm_medium = new RegExp(
          "(^|/?|&)utm_medium=([^&]*)(&|$)",
          "i",
        );
        const reg_utm_campaign = new RegExp(
          "(^|/?|&)utm_campaign=([^&]*)(&|$)",
          "i",
        );
        const reg_utm_term = new RegExp("(^|/?|&)utm_term=([^&]*)(&|$)", "i");
        const reg_utm_content = new RegExp(
          "(^|/?|&)utm_content=([^&]*)(&|$)",
          "i",
        );
        const search_ocpc_url = ckt_ocpc_url;
        console.log("search_ocpc_url:", search_ocpc_url);
        let params_utm_source = "";
        let params_utm_medium = "";
        let params_utm_term = "";
        let params_utm_campaign = "";
        let params_utm_content = "";
        search_ocpc_url &&
          search_ocpc_url.match(reg_utm_source) &&
          (params_utm_source = search_ocpc_url.match(reg_utm_source)[2]);
        search_ocpc_url &&
          search_ocpc_url.match(reg_utm_medium) &&
          (params_utm_medium = search_ocpc_url.match(reg_utm_medium)[2]);
        search_ocpc_url &&
          search_ocpc_url.match(reg_utm_campaign) &&
          (params_utm_campaign = search_ocpc_url.match(reg_utm_campaign)[2]);
        search_ocpc_url &&
          search_ocpc_url.match(reg_utm_term) &&
          (params_utm_term = search_ocpc_url.match(reg_utm_term)[2]);
        search_ocpc_url &&
          search_ocpc_url.match(reg_utm_content) &&
          (params_utm_content = search_ocpc_url.match(reg_utm_content)[2]);
        event_track_obj.utm_source = decodeURI(params_utm_source);
        event_track_obj.utm_medium = decodeURI(params_utm_medium);
        event_track_obj.utm_campaign = decodeURI(params_utm_campaign);
        event_track_obj.utm_term = decodeURI(params_utm_term);
        event_track_obj.utm_content = decodeURI(params_utm_content);
        event_track_obj.ocpc_time = ckt_ocpc_time + "";
        if (ckt_ocpc_utmState) {
          event_track_obj.ocpc_flag = "empty";
          event_track_obj.ocpc_source = event_track_obj.utm_source;
        }
        if (ckt_ocpc && ckt_ocpc.indexOf("bd_vid=") > -1) {
          opcp_obj["bdVidUrl"] = ckt_ocpc;
          let params = "";
          const reg = new RegExp("(^|/?|&)bd_vid=([^&]*)(&|$)", "i");
          const search = ckt_ocpc.substr(1);
          search && (params = search.match(reg)[2]);
          event_track_obj.ocpc_flag = params;
          event_track_obj.ocpc_source = "百度ocpc";
        }
        if (ckt_ocpc && ckt_ocpc.indexOf("bd_vid=") == -1) {
          opcp_obj["qhClickId"] = ckt_ocpc;
          event_track_obj.ocpc_source = "360ocpc";
          event_track_obj.ocpc_flag = ckt_ocpc;
        }
        console.log(event_track_obj, "222");
      } catch (error) {
        console.log(error);
      }

      const hide = await this.hideLoginWindow();

      const sdkVm = new window.$SDKVM({
        el: this.$refs.loginComp,
        appId: this.nonNullBannerAppId,
        redirectUrl: this.redirect,
        state: this.state,
        loginChannels: 381,
        eventTrackData: event_track_obj,
        //第三方来源ocpc统计
        ocpc: opcp_obj,
        loginSuccessCb: async (loginChannel) => {
          if (
            loginChannel === 10054 ||
            loginChannel === 10055 ||
            loginChannel === 10056
          ) {
            this.showRisk(loginChannel);
            return;
          }
          let getInfo = await this.$http.post(
            "/accountcenter/user/getAccountUserInfo",
            {
              client_type: 0,
              gw: true,
            },
          );
          // // 风控 异地登录次数限制
          // let LoginInfo = getInfo.body;
          // if (LoginInfo.LoginTimeOut) {
          //   if (LoginInfo.riskResult) {
          //     this.$emit("showRisk", LoginInfo.limitCount)
          //   }
          // }
          let UserInfo = getInfo.body.userInfo;
          // 新访客点击立即领取，领取尝鲜会员
          if (sessionStorage.getItem("clickedReceiveVip")) {
            this.receiveVip();
          } else {
            this.setUserInfo(UserInfo);
          }
          sessionStorage.removeItem("newIndexPageTap");
          this.showWhat(false, loginChannel === 1 ? "手机号" : "微信", true);
          // this.$message('登录成功');
        },
        signUpSuccessCb: (loginChannel) => {
          this.$message("注册成功");
          this.getUserInfo();
          this.showWhat(false, loginChannel === 1 ? "手机号" : "微信", true);
        },
        pageChange: async (val) => {
          let signData;
          switch (val) {
            case "wx":
              // 点击上报数据
              signData = {
                event: "login_show",
                event_name: "登录弹框/页面曝光",
                trigger_timing: "登录弹框或页面展示时打点（前端打点）",
                params: {
                  forward_page_name:
                    (forward && forward.forward_page_name) || "",
                  forward_module_name:
                    (forward && forward.forward_module_name) || "",
                  page_name: "微信登陆框",
                },
              };
              break;
            case "password":
              // 点击上报数据
              signData = {
                event: "login_show",
                event_name: "登录弹框/页面曝光",
                trigger_timing: "登录弹框或页面展示时打点（前端打点）",
                params: {
                  forward_page_name:
                    (forward && forward.forward_page_name) || "",
                  forward_module_name:
                    (forward && forward.forward_module_name) || "",
                  page_name: "账号密码登录框",
                },
              };
              break;
            case "phone":
              // 点击上报数据
              signData = {
                event: "login_show",
                event_name: "登录弹框/页面曝光",
                trigger_timing: "登录弹框或页面展示时打点（前端打点）",
                params: {
                  forward_page_name:
                    (forward && forward.forward_page_name) || "",
                  forward_module_name:
                    (forward && forward.forward_module_name) || "",
                  page_name: "手机验证码登录框",
                },
              };
              break;
            case "signUp":
              // 点击上报数据
              signData = {
                event: "login_show",
                event_name: "登录弹框/页面曝光",
                trigger_timing: "登录弹框或页面展示时打点（前端打点）",
                params: {
                  forward_page_name:
                    (forward && forward.forward_page_name) || "",
                  forward_module_name:
                    (forward && forward.forward_module_name) || "",
                  page_name: "手机号注册框",
                },
              };
              break;
          }
          try {
            window.gio("track", "login_show", signData.params);
            // sessionStorage.removeItem('forward')
            const { default: tracker } = await import("./tracker");
            window.tracker = tracker;
            if (window.tracker) {
              window.tracker.report(signData);
            }
          } catch (error) {
            console.log(error);
          }
        },
        ...(hide
          ? {
              otherLoginConfig: [],
              isSmsCodeLogin: false,
              isRegister: false,
              curStatus: "password",
            }
          : {}),
      });
      if (this.type == 2 || this.type == 4 || this.type == 5) {
        sdkVm.changeData("statusBeforeSignUp", "wx");
        sdkVm.changeData("curStatus", "signUp");
      }
    },
    closeTicket() {
      if (document.getElementById("tcaptcha_transform")) {
        capDestroy();
      }
    },
    showRisk(limit) {
      this.showRiskLogin = true;
      this.riskLoginLimit = limit;
    },
    closeRisk() {
      this.showRiskLogin = false;
    },
    // async getImgUrl() {
    //   let res = await this.$http.post('/banner/getBannerByTypeV2', {
    //     apidomainv2: true,
    //     banner_type: 44,
    //   });
    //   const { imgHost, list } = res.body.data;
    //   if (list.length > 0) {
    //     this.imgUrl = 'https://' + imgHost + '/' + list[0].bannerImgUrl;
    //   }
    //   console.log(this.imgUrl);
    // },
    getCaptchaJsUrl() {
      const TCaptchaScript = document.getElementById("TCaptchaScript");
      if (TCaptchaScript) {
        TCaptchaScript.parentNode.removeChild(TCaptchaScript);
      }
      this.$http
        .post("/tools/getCaptchaJsUrl", { apidomainv2: true })
        .then((res) => {
          const code = res.body.code; // 状态码：200 请求成功，-201 获取失败
          if (code === 200) {
            const captchaJsUrl = res.body.data;
            const script = document.createElement("script");
            script.src = captchaJsUrl;
            script.id = "TCaptchaScript";
            const firstScript = document.getElementsByTagName("script")[0];
            firstScript.parentNode.insertBefore(script, firstScript);
          } else {
            this.$message("获取验证码失败，错误码：" + code);
          }
        });
    },

    // getCaptchaTicket(successCb, failCb) {
    //   const cbfn = (retJson) => {
    //     if (retJson.ret === 0) {
    //       // 用户验证成功,需要校验签名
    //       successCb && successCb(retJson.ticket); // 回传用户票据
    //     } else {
    //       // 用户关闭验证码页面，没有验证
    //       failCb && failCb();
    //     }
    //   };
    //   const capOption = { callback: cbfn, type: 'popup' };
    //   try {
    //     // eslint-disable-next-line no-undef
    //     capDestroy();
    //     // eslint-disable-next-line no-undef
    //     capInit(document.getElementById('TCaptcha'), capOption);
    //   } catch (error) {
    //     setTimeout(() => {
    //       this.retryTimes++;
    //       if (this.retryTimes < 5) {
    //         this.getCaptchaTicket(successCb, failCb);
    //       }
    //     }, 500);
    //   }
    // },
    setQuickLoginShow(v) {
      this.quickLoginShow = v;
    },
    showWhat(val, type, logregInvoke) {
      this.$emit("set-logreg-show", val);
      if (val === false) {
        this.$emit("success", type, logregInvoke);
      }
    },
    receiveVip() {
      this.$emit("receiveVip");
    },
    getUserInfo() {
      this.$emit("getUserInfo");
    },
    setUserInfo(val) {
      this.$emit("setUserInfo", val);
    },
    async hideLoginWindow() {
      const domain = location.host.split(".")[0];
      const isSecondDomain = ![
        "local",
        "local-www",
        "moniwww",
        "beta",
        "www",
      ].includes(domain);
      if (!isSecondDomain) return false;
      try {
        let res = await this.$http.post("/team/fuse/custom/hideLoginWindow", {
          client_type: 0,
          gw: true,
          secondDomain: domain,
        });
        const { body: { code, data: { hide } } = {} } = res || {};
        if (code === 200) {
          return hide;
        }
      } catch (e) {
        return false;
      }
    },
  },
};
</script>
<style lang="less">
.sign-comp-wrap {
  .maxlengthtips {
    display: none;
  }
  .lt-input {
    width: 100%;
    letter-spacing: 1px;
    font-size: 14px;
    color: #1b2337;
    border: 1px solid #ebeef5;
  }
  .lt-input:hover {
    border: 1px solid #c1c6d0;
  }
  .lt-input:focus {
    background: #fff;
    border: 1px solid #0773fc;
    box-shadow: 0 0 0 2px rgba(7, 174, 252, 0.1);
  }
  input.lt-input[type="password"] {
    letter-spacing: 6px;
    color: rgba(0, 0, 0, 0.8);
  }
  input.lt-input[type="password"]::placeholder {
    letter-spacing: 1px;
  }
  .lt-form-item-error-tip {
    font-size: 12px;
  }
  .lt-btn-large {
    font-size: 14px;
  }
  .lt-form-item-error {
    .lt-input {
      border: 1px solid #ebeef5;
    }
    .lt-input:hover {
      border: 1px solid #c1c6d0;
    }
    .lt-input:focus {
      background: #fff;
      border: 1px solid #0773fc;
      box-shadow: 0 0 0 2px rgba(7, 174, 252, 0.1);
    }
  }
}
.sign-page-wrapper {
  .lt-input-wrap,
  .vcodeBox {
    input {
      width: 100% !important;
    }
  }
  .lt-form {
    .lt-form-item {
      width: 100%;
    }
  }
}
.sign-up-page {
  .sign-up {
    .lt-input-wrap {
      input {
        width: 100% !important;
      }
    }
  }
  .lt-form {
    .lt-form-item {
      width: 100%;
    }
  }
}
.retrieve-page {
  .tel-retrieve,
  .mail-retrieve {
    .lt-form {
      .lt-form-item,
      input {
        width: 100% !important;
      }
    }
  }
}
.mail-re-pw-page {
  .input {
    .lt-form {
      .lt-input-wrap {
        input {
          width: 100% !important;
        }
      }
    }
  }
}
.tel-re-pw-page {
  .lt-form {
    .lt-input-wrap {
      input {
        width: 100% !important;
      }
    }
  }
}
</style>

<style lang="less" scoped>
.all-comp {
  display: flex;
}
.comp-left {
  width: 320px;
  height: 500px;
  border-radius: 6px 0 0 6px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
}
.comp {
  position: relative;
  width: 430px;
  height: 500px;
  background-color: white;
  text-align: center;
  border-radius: 6px;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12);
}
.borderRadius {
  border-radius: 0 6px 6px 0;
}
.thirdparty-iframe {
  width: 620px;
  height: 600px;
  background-color: #fff;
}
</style>
